import React from 'react'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles'
import purple from '@material-ui/core/colors/purple'

// fonts are imported in pages/_app.js
export const fontPrimary = 'Overpass, sans-serif'
export const fontSecondary = 'Montserrat, sans-serif'

const themeObj = {
  typography: {
    useNextVariants: true,
    fontFamily: fontPrimary,
    fontSecondary: fontSecondary,
    body1: {
      fontSize: '1.1rem',
      lineHeight: '1.3em',
    },
    body2: {
      fontSize: '1rem',
    },
    h1: {
      fontSize: '2.2rem',
      fontFamily: fontSecondary,
    },
    h2: {
      fontFamily: fontSecondary,
      fontSize: '2rem',
    },
  },
  overrides: {
    MuiIconButton: {
      root: {
        borderRadius: 0,
      },
    },
    MuiButton: {
      root: {
        minWidth: 'auto',
        minHeight: 'auto',
        textTransform: 'none',
      },
      contained: {
        padding: '4px 6px 4px 6px',
        borderRadius: '0px',
        whiteSpace: 'nowrap',
        margin: '4px',
        boxShadow: 'unset',
      },
      containedPrimary: {
        boxShadow: '3px 3px 0px rgba(0,0,0,0.1)',
      },
    },
  },
  palette: {
    primary: {
      ...purple,
      500: '#8a2be2',
    },
    secondary: {
      50: '#ffeceb',
      100: '#ffd1cc',
      200: '#ffb2aa',
      300: '#ff9388',
      400: '#ff7b6f',
      500: '#ff6455',
      600: '#ff5c4e',
      700: '#ff5244',
      800: '#ff483b',
      900: '#ff362a',
      A100: '#ffffff',
      A200: '#ffffff',
      A400: '#ff6455',
      A700: '#ff483b',
      contrastDefaultColor: 'dark',
    },
    background: {
      default: '#fbf8ff',
      player: 'lavender',
      entity: 'lightgoldenrodyellow',
    },
  },
}

export const mainTheme = createMuiTheme(themeObj)

const darkTheme = createMuiTheme({
  ...themeObj,
  palette: {
    ...themeObj.palette,
    background: {
      ...themeObj.palette.background,
      default: '#333333',
    },
    type: 'dark',
  },
  overrides: {
    ...themeObj.overrides,
    MuiPickersYear: {
      yearSelected: {
        color: 'white',
      },
    },
    MuiPickersMonth: {
      monthSelected: {
        color: 'white',
      },
    },
    MuiPickersModal: {
      withAdditionalAction: {
        '& button': {
          color: 'white',
        },
      },
    },
  },
})
const mapTheme = {
  ...mainTheme,
  typography: {
    ...mainTheme.typography,
    body1: {
      ...mainTheme.typography.body1,
      fontSize: '1rem',
    },
    h5: {
      ...mainTheme.typography.h5,
      fontSize: '1.2rem',
      fontWeight: 600,
      fontFamily: fontSecondary,
    },
  },
}

const withTheme = (theme) => (Component) => (props) => {
  return (
    <MuiThemeProvider theme={theme}>
      <Component {...props} />
    </MuiThemeProvider>
  )
}

export const withMapTheme = withTheme(mapTheme)
export const withDarkTheme = withTheme(darkTheme)

export const WithDarkTheme = withDarkTheme((props) => props.children)
